/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-06-15 15:44:23
 * @LastEditors: renmingming
 * @LastEditTime: 2022-10-18 16:23:48
 */
import request from '@/utils/request';
import { BaseResponse } from '@/interface';

const http = {
  /**
   * @description: get请求
   * @param {*}
   * @return {*}
   */
  get: <T>(
    url: string,
    params: Record<string, any> = {},
    config: Record<string, any> = {}
  ): Promise<T> => {
    return request({
      url: `${url}`,
      method: 'get',
      params,
      ...config,
    });
  },
  /**
   * POST请求
   * @param {String} url - 接口地址
   */
  post: <T>(
    url: string,
    data: Record<string, any> = {},
    config: Record<string, any> = {}
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'post',
      data,
      ...config,
    });
  },
  /**
   * put请求
   * @param {String} url - 接口地址
   */
  put: <T>(
    url: string,
    data: Record<string, any> = {}
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'put',
      data,
    });
  },
  /**
   * @description: delete请求
   * @param {String} url
   * @return {*}
   */
  delete: <T>(
    url: string,
    data: Record<string, any> = {}
  ): Promise<BaseResponse<T>> => {
    return request({
      url: `${url}`,
      method: 'delete',
      data,
    });
  },
};

export default http;
