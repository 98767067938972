/*
 * @Descripttion:
 * @Author: sunchen
 * @Date: 2021-10-08 10:35:00
 * @LastEditors: sunchen
 * @LastEditTime: 2021-10-08 17:21:20
 */
import Vue from 'vue';
// 获取当前文件夹下的的组件
// require.context 三个参数  第一个表示读取文件的路径  第二个表示是否遍历文件的子目录   第三个表示匹配的文件的正则
const componentsContext = require.context('.', true, /index.vue$/);

// 组件必须有name属性;
componentsContext.keys().forEach((component) => {
  const componentConfig = componentsContext(component).default.extendOptions;
  Vue.component(componentConfig.name, componentConfig);
});
