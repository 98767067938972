/*
 * @Description: 全局路由守卫
 * @Author: zhangguoliang
 * @Date: 2021-08-03 17:02:51
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-01-24 12:08:18
 */

import router from '@/router';
import store from '@/store';
import { isEmpty } from 'lodash';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Cookies from 'js-cookie';

NProgress.configure({ showSpinner: false });

// router.beforeEach(async (to, from, next) => {
//   NProgress.start();
//   const { token } = to.query;
//   if (token) {
//     store.commit('SET_TOKEN', token);
//     if (process.env.VUE_APP_MODE === 'prod') {
//       Cookies.set('token', token, { expires: 0.5 });
//     }
//     next(to.path);
//     return;
//   }
//   if (!token && !store.getters.token) {
//     location.replace(process.env.VUE_APP_LOGIN_URL + '?logout=1');
//     next(false);
//     return;
//   }
//   if (isEmpty(store.getters.userInfo)) {
//     await store.dispatch('getInfo');
//   }
//   next();
// });

// router.afterEach((to) => {
//   document.title = to.meta?.title || '应用服务平台';
//   NProgress.done();
// });
