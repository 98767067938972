/*
 * @Descripttion:
 * @Author: liang
 * @Date: 2024-09-04 14:48:32
 * @LastEditors: liang
 * @LastEditTime: 2024-09-05 11:20:42
 */
export const whiteList = [
  `/portal-resources/1/portalRewardRecord/`,
  `/portal-resources/1/portalRecommend/applyNow`,
  `/portal-resources/1/portalRewardRecord/summary/`,
  `/agency-resource/1/agencies/userAgency`,
  `/portal-resources/1/PortalWxInfo/checkOpenId`,
  `/portal-resources/1/PortalWxInfo/bindOpenId/`,
  `/portal-resources/1/portalAccount/accountDetailCount`,
  `/portal-resources/1/portalAccountDetail/queryExpenditureList`,
  `/portal-resources/1/portalAccount/withdrawal`,
  '/portal-resources/1/PortalForward/forward',
];
