import http from '@/utils/http';

export function mobile_smsCode(mobile: string): any {
  return http.get(`/auth-resource/1/2/${mobile}/smsCode`);
}

//验证手机是否注册
export function mobile_verify(mobile: string): any {
  return http.get(`/auth-resource/1/phone/${mobile}/verify`);
}
export function mobile_login(reqData: any): any {
  return http.post(`/auth-resource/oauth/token`, reqData, {
    headers: {
      PRODUCT_CODE: 'AGENCY_ADMIN',
      Authorization: 'Basic QUdFTkNZX0FETUlOOnhqbGlkb25nMTIz',
    },
  });
}

export function getRecommendList(inviterMobile: string, token: string): any {
  return http.get(
    `/portal-resources/1/portalRewardRecord/${inviterMobile}`,
    {},
    {
      // headers: {
      //   Application_name: 'PORTAL',
      //   PRODUCT_CODE: 'AGENCY_ADMIN',
      //   Authorization: 'bearer ' + token,
      // },
    }
  );
}

export function applyRecommend(reqData: any, token: string): any {
  return http.post(`/portal-resources/1/portalRecommend/applyNow`, reqData, {
    // headers: {
    //   Application_name: 'PORTAL',
    //   PRODUCT_CODE: 'AGENCY_ADMIN',
    //   Authorization: 'bearer ' + token,
    // },
  });
}

export function getPortalRewardRecord(mobile: string, token: string): any {
  return http.get(
    `/portal-resources/1/portalRewardRecord/summary/${mobile}`,
    {},
    {
      // headers: {
      //   Application_name: 'PORTAL',
      //   PRODUCT_CODE: 'AGENCY_ADMIN',
      //   Authorization: 'bearer ' + token,
      // },
    }
  );
}
export function getUserAgency(token: string): any {
  return http.get(
    `/agency-resource/1/agencies/userAgency`,
    {},
    {
      // headers: {
      //   Application_name: 'PORTAL',
      //   PRODUCT_CODE: 'AGENCY_ADMIN',
      //   Authorization: 'bearer ' + token,
      // },
    }
  );
}
export function portalForward(): any {
  return http.get(`/portal-resources/1/PortalForward/forward`);
}

export function getUserInfoByOpenId(wxCode) {
  return http.get(`/portal-resources/1/PortalWxInfo/getUserInfoByOpenId/${wxCode}`)
}
