/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 10:26:26
 * @LastEditors: liang
 * @LastEditTime: 2024-09-05 11:27:03
 */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import PageLayout from '@/layout/pageLayout.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'page',
    redirect: '/home',
    component: PageLayout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: 'coupons',
        name: 'coupons',
        component: () => import('@/views/coupons.vue'),
      },
      {
        path: 'appointment',
        name: 'appointment',
        component: () => import('@/views/appointment.vue'),
      },
      {
        path: 'biddingPlan',
        name: 'biddingPlan',
        component: () => import('@/views/biddingPlan.vue'),
      },
      {
        path: 'biddingCost',
        name: 'biddingCost',
        component: () => import('@/views/biddingCost.vue'),
      },
      {
        path: 'bidding',
        name: 'bidding',
        component: () => import('@/views/bidding.vue'),
      },
      {
        path: 'newCoupons',
        name: 'newCoupons',
        component: () => import('@/views/newCoupons.vue'),
      },
      {
        path: 'invite',
        name: 'invite',
        component: () => import('@/views/invite/index.vue'),
      },
      {
        path: 'record',
        name: 'record',
        component: () => import('@/views/invite/record.vue'),
      },
      {
        path: 'openBetaInvite',
        name: 'openBetaInvite',
        component: () => import('@/views/openBetaInvite/index.vue'),
      },
      {
        path: 'openBetaShare',
        name: 'openBetaShare',
        component: () => import('@/views/openBetaInvite/share-login.vue'),
      },
      {
        path: 'openBetaShareEwm',
        name: 'openBetaShareEwm',
        component: () => import('@/views/openBetaInvite/share-ewm.vue'),
      },
      {
        path: 'openBetaInviteRecord',
        name: 'openBetaInviteRecord',
        component: () => import('@/views/openBetaInvite/invite-record.vue'),
      },
      {
        path: 'withdraw',
        name: 'withdraw',
        component: () => import('@/views/withdraw/index.vue'),
      },
      {
        path: '404',
        name: '404',
        component: () => import('@/views/error/404.vue'),
      },
    ],
  },
  {
    path: '/userFeedback',
    name: 'userFeedback',
    component: () => import('@/views/userFeedback/index.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

export const errorRoutes: RouteConfig[] = [
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/error/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
