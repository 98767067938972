/*
 * @Author: zhangguoliang
 * @Date: 2021-09-22 10:26:26
 * @LastEditors: liang
 * @LastEditTime: 2024-09-05 10:49:38
 */
require('@/components/global/index');
import '@/assets/style/commons.less';

import Vue from 'vue';
import router from './router';
import store from './store';
import 'amfe-flexible';

import {
  Icon,
  message,
  FormModel,
  Input,
  Menu,
  Layout,
  Breadcrumb,
  // Tabs,
  Select,
  Radio,
  Switch,
  // Checkbox,
  DatePicker,
  Upload,
  Table,
  Divider,
  Tag,
  Dropdown,
  Row,
  Col,
  Modal,
  Spin,
  Tree,
  InputNumber,
  Pagination,
  ConfigProvider,
  List,
  Avatar,
  Steps,
  Popover,
  Tooltip,
} from 'ant-design-vue';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import '@/permission';
import {
  readFromStorage,
  save2Storage,
  // readFromCookie,
} from '@/utils/store2storage';
import App from './App.vue';

Vue.config.productionTip = false;

// 云造价新平台，用到了里面的部分
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2841474_iqbqjw8b2cb.js',
});

const IconFonts = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2957314_1l1mgq4req3.js',
});

window.addEventListener('beforeunload', save2Storage);

readFromStorage();
// readFromCookie();

Vue.component('IconFont', IconFont);
Vue.component('IconFont', IconFonts);

Vue.use(FormModel)
  .use(Input)
  .use(Layout)
  .use(Breadcrumb)
  // .use(Tabs)
  .use(Select)
  .use(Radio)
  .use(Menu)
  .use(Switch)
  // .use(Checkbox)
  .use(DatePicker)
  .use(Upload)
  .use(Table)
  .use(Divider)
  .use(Tag)
  .use(Dropdown)
  .use(Row)
  .use(Col)
  .use(Modal)
  .use(Spin)
  .use(Tree)
  .use(InputNumber)
  .use(Icon)
  .use(Pagination)
  .use(ConfigProvider)
  .use(List)
  .use(Avatar)
  .use(Steps)
  .use(Tooltip)
  .use(Popover);
Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$zhCN = zhCN;
// Vue.prototype.$api = api;

// import utils from '@/utils/commonUtil.ts';
// Vue.prototype.$utils = utils;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

import {
  Dialog,
  Field,
  Form,
  Button,
  Notify,
  Image as VanImage,
  CountDown,
  Toast,
  Area,
  ActionSheet,
  Popup,
  Overlay,
  Checkbox,
  Tab,
  Tabs,
  Picker,
  Uploader,
  Loading,
  ShareSheet,
} from 'vant';
import 'vant/lib/index.css';
import { use } from 'vue/types/umd';
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Form);
Vue.use(Button);
Vue.use(Notify);
Vue.use(VanImage);
Vue.use(CountDown);
Vue.use(Toast);
Vue.use(Area);
Vue.use(ActionSheet);
Vue.use(Popup);
Vue.use(Overlay);
Vue.use(Checkbox);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Picker);
Vue.use(Uploader);
Vue.use(Loading);
Vue.use(ShareSheet);
