/*
 * @Description: save store to storage or read from storage.
 * @Author: zhangguoliang
 * @Date: 2021-08-03 16:35:23
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-01-24 12:08:15
 */
import store from '@/store';
// import Cookies from 'js-cookie';

const props = ['menuKey', 'dictList'];

// const cookiePros = ['token'];

export function readFromStorage(): void {
  props.forEach((prop) => {
    const storage = sessionStorage.getItem(prop);
    if (!storage) return;
    let parse;
    try {
      parse = JSON.parse(storage);
    } catch (e) {
      parse = storage;
    }
    store.commit('SET_' + prop.toUpperCase(), parse);
  });
}

export function save2Storage(): void {
  props.forEach((prop) => {
    const value = store.getters[prop];
    if (!value) return;
    sessionStorage.setItem(prop, JSON.stringify(value));
  });
}

// export function readFromCookie(): void {
//   cookiePros.forEach((prop) => {
//     const cookie = Cookies.get(prop);
//     if (!cookie) return;
//     let parse;
//     try {
//       parse = JSON.parse(cookie);
//     } catch (e) {
//       parse = cookie;
//     }
//     store.commit('SET_' + prop.toUpperCase(), parse);
//   });
// }
