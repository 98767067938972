/*
 * @Description:
 * @Author: zhangguoliang
 * @Date: 2021-08-02 17:09:13
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-03-31 16:43:30
 */
import Vue from 'vue';
import Vuex from 'vuex';
import common from './common';
import RootState from './interface';

Vue.use(Vuex);

export default new Vuex.Store<RootState>({
  modules: {
    common,
  },
});
